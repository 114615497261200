import React from "react"
import { Link } from "gatsby"
import artificialimage from "../../assets/images/banners/service-images/artificial.jpg"
// import quality from "../../assets/images/icons/mitech-box-image-style-06-image-03-120x120.webp"
// import speed from "../../assets/images/icons/mitech-box-image-style-06-image-02-120x120.webp"
// import security from "../../assets/images/icons/mitech-box-image-style-06-image-03-120x120.webp"

import cost from '../../assets/images/banners/icon-pack/money.png'
import integration from '../../assets/images/banners/icon-pack/technology.png'
import customer from '../../assets/images/banners/icon-pack/customer-service.png'
import security from '../../assets/images/banners/icon-pack/secure-shield.png'
import transformation from '../../assets/images/banners/icon-pack/data-transformation.png'
import discretion from '../../assets/images/banners/icon-pack/discrete-math.png'

import ml from '../../assets/images/banners/icon-pack/ai.png'
import cv from '../../assets/images/banners/icon-pack/vision.png'
import pa from '../../assets/images/banners/icon-pack/predictive-chart.png'
import nlp from '../../assets/images/banners/icon-pack/natural-language-processing.png'

const ArtificialIntelligence = () => {
  return (
    <div id="main-wrapper">
      <div className="site-wrapper-reveal">
        <div
          className="about-banner-wrap banner-space bg-img artificial"
          data-bg="assets/images/banners/service-banners/artificial-intel.jpg"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-8 m-auto">
                <div className="about-banner-content text-center">
                  <h1 className="mb-15 text-white">Artificial Intelligence</h1>
                  <h5
                    className="font-weight--normal text-white"
                    style={{ textTransform: "capitalize" }}
                  >
                    With Our AI Services, We Have Helped Numerous Businesses
                    Become More Operationally Agile And Efficient.
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!--=========== About Company Area Start ==========-->
             <!--=========== Service Projects Wrapper Start =============--> */}
        <div className="service-projects-wrapper section-space--ptb_80">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-wrap text-center section-space--mb_60">
                  <h6 className="section-sub-title">Our IT Service</h6>
                </div>
              </div>
            </div>
            <div className="">
              <div className="">
                <div className="">
                  <div className="row">
                    <div className="col-lg-6 col-xl-6 order-2 order-lg-1 v1-d-f">
                      <div className="v1-dt-box-0-text">
                        <h2 className="heading">Artificial <span className="text-color-primary">Intelligence</span></h2>
                        At Aamanto we use blockchain and AI to turn
                        sophisticated products into quick fixes. We have a
                        large team of highly qualified AI developers and
                        Consultants who have executed end-to-end initiatives
                        in machine learning, natural language processing,
                        computer vision, and predictive analysis. We are a
                        leading machine learning startup that uses powerful
                        algorithms to assist organizations to automate
                        processes and prioritize regular decision-making. This
                        reduces the risk of human mistakes and allows us to
                        move away from traditional rule-based procedures and
                        toward more intelligent ones, allowing us to find new
                        unstructured data sets and patterns.
                      </div>
                    </div>

                    <div className="col-lg-6 col-xl-6 order-1 order-lg-2">
                      <div className="v1-dt-box-0-img">
                        <img className="img-fluid" src={artificialimage} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!--=========== Service Projects Wrapper End =============-->

           <!--===========  feature-large-images-wrapper  Start =============--> */}
        <div className="feature-large-images-wrapper section-space--pb_80">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                {/* <!-- section-title-wrap Start --> */}
                <div className="section-title-wrap text-center section-space--mb_60">
                  <h2 className="heading">Advantages of Artificial Intelligence in your
                    <span className="text-color-primary"> Business Transformation Journey</span>
                  </h2>
                </div>
                {/* <!-- section-title-wrap Start --> */}
              </div>
            </div>

            {/* <div className="row">
              <div className="col-12">
                <div className="feature-images__six">
                  <div className="row">
                    <div className="col-lg-4 col-md-6 wow move-up">
                      <div className="ht-box-images style-06">
                        <div className="image-box-wrap">
                          <div className="box-image">
                            <div className="default-image">
                              <img className="img-fulid" src={quality} alt="" />
                            </div>
                          </div>
                          <div className="content">
                            <h5 className="heading">Reduced Cost</h5>
                            <div className="text">
                              Artificial intelligence technologies integrated
                              into your organization would not only share your
                              workload but also save you money. Empower
                              artificial intelligence to engage with your
                              company at a lower cost.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wow move-up">
                      <div className="ht-box-images style-06">
                        <div className="image-box-wrap">
                          <div className="box-image">
                            <div className="default-image">
                              <img className="img-fulid" src={speed} alt="" />
                            </div>
                          </div>
                          <div className="content">
                            <h5 className="heading">Integrated Information</h5>
                            <div className="text">
                              Artificial intelligence technologies integrated
                              into your organization would not only share your
                              workload but also save you money. Empower
                              artificial intelligence to engage with your
                              company at a lower cost.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wow move-up">
                      <div className="ht-box-images style-06">
                        <div className="image-box-wrap">
                          <div className="box-image">
                            <div className="default-image">
                              <img
                                className="img-fulid"
                                src={security}
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="content">
                            <h5 className="heading">
                              Customer Service Improvements
                            </h5>
                            <div className="text">
                              Artificial intelligence (AI) solutions make it
                              easier to communicate with your consumers. Your
                              clients will be able to identify with your brand
                              if you provide them with a tailored communication
                              experience.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wow move-up">
                      <div className="ht-box-images style-06">
                        <div className="image-box-wrap">
                          <div className="box-image">
                            <div className="default-image">
                              <img className="img-fulid" src={speed} alt="" />
                            </div>
                          </div>
                          <div className="content">
                            <h5 className="heading">Better Security</h5>
                            <div className="text">
                              The most pressing problem for most firms these
                              days is data security. You can keep your
                              confidential information safe and secure by
                              integrating AI technology into your organization.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wow move-up">
                      <div className="ht-box-images style-06">
                        <div className="image-box-wrap">
                          <div className="box-image">
                            <div className="default-image">
                              <img
                                className="img-fulid"
                                src={security}
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="content">
                            <h5 className="heading">Digital Transformation</h5>
                            <div className="text">
                              AI has played a role in the digital transformation
                              of both large and small businesses. There are
                              numerous considerations to be made at first. Our
                              team's AI support helps you in the efficient
                              implementation and control of your business.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-4 col-md-6 wow move-up">
                      <div className="ht-box-images style-06">
                        <div className="image-box-wrap">
                          <div className="box-image">
                            <div className="default-image">
                              <img className="img-fulid" src={speed} alt="" />
                            </div>
                          </div>
                          <div className="content">
                            <h5 className="heading">Complete Discretion</h5>
                            <div className="text">
                              You will have complete transparency and access to
                              all the data and additional integrations we offer.
                              We make sure that the project development is
                              transparent and that you are aware of where your
                              solution is at all times.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}


            <div class="v1-box-2-parent">
              <div class="v1-box-2-child">
                <div className="v1-box-2-content">
                  <Link to="/">
                    <div className="v1-default-box-2">
                      <div className="row">
                        <div className="col-md-3 v1-img-2-1">
                          <img className="img-fluid" src={cost} alt="" />
                        </div>
                        <div className="col-md-9">
                          <div className="v1-content-2">
                            <h5 className="v1-heading-2">Reduced Cost</h5>
                            <div className="v1-text-2">
                              Artificial intelligence technologies integrated into your organization would not only share your workload but also save you money. Empower artificial intelligence to engage with your company at a lower cost.
                            </div>
                            <div className="v1-box-images-arrow-2">
                              <span className="button-text">Read More</span>
                              <i className="far fa-long-arrow-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              <div class="v1-box-2-child">
                <div className="v1-box-2-content">
                  <Link to="/">
                    <div className="v1-default-box-2">
                      <div className="row">
                        <div className="col-md-3 v1-img-2-1">
                          <img className="img-fluid" src={integration} alt="" />
                        </div>
                        <div className="col-md-9">
                          <div className="v1-content-2">
                            <h5 className="v1-heading-2">Integrated Information</h5>
                            <div className="v1-text-2">
                              Artificial intelligence technologies integrated into your organization would not only share your workload but also save you money. Empower artificial intelligence to engage with your company at a lower cost.
                            </div>
                            <div className="v1-box-images-arrow-2">
                              <span className="button-text">Read More</span>
                              <i className="far fa-long-arrow-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              <div class="v1-box-2-child">
                <div className="v1-box-2-content">
                  <Link to="/">
                    <div className="v1-default-box-2">
                      <div className="row">
                        <div className="col-md-3 v1-img-2-1">
                          <img className="img-fluid" src={customer} alt="" />
                        </div>
                        <div className="col-md-9">
                          <div className="v1-content-2">
                            <h5 className="v1-heading-2">Customer Service Improvements</h5>
                            <div className="v1-text-2">
                              Artificial intelligence (AI) solutions make it easier to communicate with your consumers. Your clients will be able to identify with your brand if you provide them with a tailored communication experience.
                            </div>
                            <div className="v1-box-images-arrow-2">
                              <span className="button-text">Read More</span>
                              <i className="far fa-long-arrow-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              <div class="v1-box-2-child">
                <div className="v1-box-2-content">
                  <Link to="/">
                    <div className="v1-default-box-2">
                      <div className="row">
                        <div className="col-md-3 v1-img-2-1">
                          <img className="img-fluid" src={security} alt="" />
                        </div>
                        <div className="col-md-9">
                          <div className="v1-content-2">
                            <h5 className="v1-heading-2">Better Security</h5>
                            <div className="v1-text-2">
                              The most pressing problem for most firms these days is data security. You can keep your confidential information safe and secure by integrating AI technology into your organization.
                            </div>
                            <div className="v1-box-images-arrow-2">
                              <span className="button-text">Read More</span>
                              <i className="far fa-long-arrow-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              <div class="v1-box-2-child">
                <div className="v1-box-2-content">
                  <Link to="/">
                    <div className="v1-default-box-2">
                      <div className="row">
                        <div className="col-md-3 v1-img-2-1">
                          <img className="img-fluid" src={transformation} alt="" />
                        </div>
                        <div className="col-md-9">
                          <div className="v1-content-2">
                            <h5 className="v1-heading-2">Digital Transformation</h5>
                            <div className="v1-text-2">
                              AI has played a role in the digital transformation of both large and small businesses. There are numerous considerations to be made at first. Our team's AI support helps you in the efficient implementation and control of your business.
                            </div>
                            <div className="v1-box-images-arrow-2">
                              <span className="button-text">Read More</span>
                              <i className="far fa-long-arrow-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              <div class="v1-box-2-child">
                <div className="v1-box-2-content">
                  <Link to="/">
                    <div className="v1-default-box-2">
                      <div className="row">
                        <div className="col-md-3 v1-img-2-1">
                          <img className="img-fluid" src={discretion} alt="" />
                        </div>
                        <div className="col-md-9">
                          <div className="v1-content-2">
                            <h5 className="v1-heading-2">Complete Discretion</h5>
                            <div className="v1-text-2">
                              You will have complete transparency and access to all the data and additional integrations we offer. We make sure that the project development is transparent and that you are aware of where your solution is at all times.
                            </div>
                            <div className="v1-box-images-arrow-2">
                              <span className="button-text">Read More</span>
                              <i className="far fa-long-arrow-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div >

            </div >


          </div >
        </div >
        {/* <!--===========  feature-large-images-wrapper  End =============-->
            <!--=========== About Company Area End ==========-->
            
              <!--===========  feature-icon-wrapper  Start =============--> */}
        < div className="feature-icon-wrapper section-space--pb_80" >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title-wrap text-center section-space--mb_60">
                  <h2 className="heading">What Services We Offer</h2>
                </div>
              </div>
            </div>

            {/* <div className="row">
              <div className="col-12">
                <div className="feature-list__three">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="grid-item animate">
                        <div className="ht-box-icon style-03">
                          <div className="icon-box-wrap">
                            <div className="content-header">
                              <div className="icon">
                                <i className="fal fa-shield-check"></i>
                              </div>
                              <h5 className="heading">Machine Learning</h5>
                            </div>
                            <div className="content">
                              <div className="text">
                                Artificial intelligence and machine learning
                                have enabled organizations to use highly
                                personalized solutions equipped with advanced
                                Machine Learning Techniques.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="grid-item animate">
                        <div className="ht-box-icon style-03">
                          <div className="icon-box-wrap">
                            <div className="content-header">
                              <div className="icon">
                                <i className="fal fa-lock-alt"></i>
                              </div>
                              <h5 className="heading">Computer Vision</h5>
                            </div>
                            <div className="content">
                              <div className="text">
                                Visual data analysis has been scaled up, new
                                image-based functions have been created, and the
                                way firms from many verticals use video elements
                                has been altered due to computer vision
                                technology.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="grid-item animate">
                        <div className="ht-box-icon style-03">
                          <div className="icon-box-wrap">
                            <div className="content-header">
                              <div className="icon">
                                <i className="fal fa-magnet"></i>
                              </div>
                              <h5 className="heading">Predictive Analysis</h5>
                            </div>
                            <div className="content">
                              <div className="text">
                                Aamanto’s predictive analytics solutions deliver
                                accurate and timely findings, allowing you to
                                spot changes and anomalies in your business
                                strategies and processes.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="grid-item animate">
                        <div className="ht-box-icon style-03">
                          <div className="icon-box-wrap">
                            <div className="content-header">
                              <div className="icon">
                                <i className="fal fa-shield-check"></i>
                              </div>
                              <h5 className="heading">
                                Natural Learning Processing
                              </h5>
                            </div>
                            <div className="content">
                              <div className="text">
                                Aamanto provides powerful Natural Language
                                Processing (NLP) applications that are designed
                                with cutting-edge techniques Applications for
                                Natural Language Processing.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}


            <div class="v1-box-2-parent">
              <div class="v1-box-2-child">
                <div className="v1-box-2-content">
                  <Link to="/">
                    <div className="v1-default-box-2">
                      <div className="row">
                        <div className="col-md-3 v1-img-2-1">
                          <img className="img-fluid" src={ml} alt="" />
                        </div>
                        <div className="col-md-9">
                          <div className="v1-content-2">
                            <h5 className="v1-heading-2">Machine Learning</h5>
                            <div className="v1-text-2">
                              Artificial intelligence and machine learning have enabled organizations to use highly personalized solutions equipped with advanced Machine Learning Techniques.
                            </div>
                            <div className="v1-box-images-arrow-2">
                              <span className="button-text">Read More</span>
                              <i className="far fa-long-arrow-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              <div class="v1-box-2-child">
                <div className="v1-box-2-content">
                  <Link to="/">
                    <div className="v1-default-box-2">
                      <div className="row">
                        <div className="col-md-3 v1-img-2-1">
                          <img className="img-fluid" src={cv} alt="" />
                        </div>
                        <div className="col-md-9">
                          <div className="v1-content-2">
                            <h5 className="v1-heading-2">Computer Vision</h5>
                            <div className="v1-text-2">
                              Visual data analysis has been scaled up, new image-based functions have been created, and the way firms from many verticals use video elements has been altered due to computer vision technology.
                            </div>
                            <div className="v1-box-images-arrow-2">
                              <span className="button-text">Read More</span>
                              <i className="far fa-long-arrow-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>

              <div class="v1-box-2-child">
                <div className="v1-box-2-content">
                  <Link to="/">
                    <div className="v1-default-box-2">
                      <div className="row">
                        <div className="col-md-3 v1-img-2-1">
                          <img className="img-fluid" src={pa} alt="" />
                        </div>
                        <div className="col-md-9">
                          <div className="v1-content-2">
                            <h5 className="v1-heading-2">Predictive Analysis</h5>
                            <div className="v1-text-2">
                              Aamanto’s predictive analytics solutions deliver accurate and timely findings, allowing you to spot changes and anomalies in your business strategies and processes.
                            </div>
                            <div className="v1-box-images-arrow-2">
                              <span className="button-text">Read More</span>
                              <i className="far fa-long-arrow-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </Link>
                </div>
              </div>

              <div class="v1-box-2-child">
                <div className="v1-box-2-content">
                  <Link to="/">
                    <div className="v1-default-box-2">
                      <div className="row">
                        <div className="col-md-3 v1-img-2-1">
                          <img className="img-fluid" src={nlp} alt="" />
                        </div>
                        <div className="col-md-9">
                          <div className="v1-content-2">
                            <h5 className="v1-heading-2">Natural Learning Processing</h5>
                            <div className="v1-text-2">
                              Aamanto provides powerful Natural Language Processing (NLP) applications that are designed with cutting-edge techniques Applications for Natural Language Processing.
                            </div>
                            <div className="v1-box-images-arrow-2">
                              <span className="button-text">Read More</span>
                              <i className="far fa-long-arrow-right"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div >

            </div >

          </div >
        </div >
        {/* <!--===========  feature-icon-wrapper  End =============--> */}
      </div >
    </div >
  )
}
export default ArtificialIntelligence
