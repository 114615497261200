import * as React from "react"
import Layout from "../components/layout"
import ArtificialIntelligence from "../components/Services/ArtificialIntelligence"

const artificial = () => {
  return (
    <Layout>
      <ArtificialIntelligence />
    </Layout>
  )
}
export default artificial
